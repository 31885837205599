"use strict";

var focusHelper = require("base/components/focus");
var dataLayerHelper = require("./dataLayer");

var ACCEPT_ALL = {
  ad_storage: "granted",
  analytics_storage: "granted",
  personalization_storage: "granted",
};

var REJECT_ALL = {
  ad_storage: "denied",
  analytics_storage: "denied",
  personalization_storage: "denied",
};

var ACCEPT_ALL_COOKIES = {
  ...ACCEPT_ALL,
  date: Date.now(),
};

var NEW_COOKIE_NAME = "_iub_cs"
var LANGUAGE_COOKIE_NAME = "SFCC_Language";

const COOKIES_POLICY_CUSTOMIZATION = "cookiesPolicyCustomization";
const ITA_SPA_COOKIE_CONFIRMED = "itaSpaCookieConfirmed";

function getDomainWithoutSubdomain(url) {
  var urlParts = new URL(url).hostname.split(".");

  return urlParts.slice(0).slice(-2).join(".");
}

function createCookie(name, value, days) {
  var expires;
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toGMTString();
  } else {
    expires = "";
  }
  var domain = getDomainWithoutSubdomain(window.location.href);
  document.cookie = name + "=" + value + expires + "; path=/; Domain=." + domain;
}

function checkCookie(c_name, get) {
  var cookies = document.cookie.split(';');
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i].trim();
    if (cookie.startsWith(c_name)) {
      var name = cookie.split('=')[0];
      return get ? name : true;
    }
  }
  return false;
}

function getCookie(c_name) {
  if (document.cookie.length > 0) {
    var c_start = document.cookie.indexOf(c_name + "=");
    if (c_start != -1) {
      c_start = c_start + c_name.length + 1;
      var c_end = document.cookie.indexOf(";", c_start);
      if (c_end == -1) {
        c_end = document.cookie.length;
      }
      return unescape(document.cookie.substring(c_start, c_end));
    }
  }
  return "";
}

/**
 *
 *   Renders a modal window that will allow the users  to personalize the site tracking policy
 */

function showPersonalizatioModal(urlPersonalize) {
  var trackingConsentData = $(".tracking-consent");
  var tokenName = trackingConsentData.data("tokenname");
  var token = trackingConsentData.data("token");

  $.get(urlPersonalize, function (data) {
    var $modal = $($.parseHTML(data));
    $("body").append($modal);
    setTimeout(() => {
      $("#personalizeTracking").modal();
      $("#personalizeTracking").on("hidden.bs.modal", function (event) {
        setTimeout(() => {
          $modal.remove();
        }, 1000);
      });
    }, 10);

    $("#personalizeTracking form").on("submit", function (e) {
      e.preventDefault();
      const $form = $(this);
      const url = $form.attr("action");
      const method = $form.attr("method");

      const userCookies = $form.serializeArray().reduce((acc, curr) => {
        acc[curr.name] = curr.value;
        return acc;
      }, {});

      var data =$form.serialize()+'&'+tokenName+'='+token

      $.ajax({
        type: method,
        url: url,
        data: data,
        success: function () {
          createCookie(COOKIES_POLICY_CUSTOMIZATION, JSON.stringify(userCookies), 180);
          createCookie(ITA_SPA_COOKIE_CONFIRMED, "true", 180);
          dataLayerHelper.methods.pushDataLayer(userCookies);
          $("#consent-tracking").remove();
          $("#personalizeTracking").modal("toggle");
        },
        error: function () {
          // Log error
          console.error("Error on cookie customization");
          $("#consent-tracking").remove();
          $("#personalizeTracking").modal("toggle");
        },
      });
    });
  });
}

/**
 * Renders a modal window that will track the users consenting to accepting site tracking policy
 */
function showConsentModal() {
  var trackingConsentData = $(".tracking-consent");
  if (!trackingConsentData.data("caonline")) {
    return;
  }

  var urlContent = trackingConsentData.data("url");
  var urlAccept = trackingConsentData.data("accept");
  var urlReject = trackingConsentData.data("reject");
  var urlPersonalize = trackingConsentData.data("personalize");
  var textYes = trackingConsentData.data("accepttext");
  var textNo = trackingConsentData.data("rejecttext");
  var textHeader = trackingConsentData.data("heading");
  var tokenName = trackingConsentData.data("tokenname");
  var token = trackingConsentData.data("token");

  var htmlString =
    "<!-- Modal -->" +
    '<div class="consent fixed-bottom" id="consent-tracking" >' +
    '<div class="consent-dialog m-0 mw-100 consent-tracking-dialog p-0">' +
    "<!-- consent content-->" +
    '<button class="close-btn" data-dismiss="modal">' +
    "X" +
    "</button>" +
    '<div class="consent-content d-block d-lg-flex flex-row justify-content-center align-items-center p-3">' +
    '<div class="consent-body consent-body"></div>' +
    '<div class="button-wrapper">' +
    '<button class="personalize btn " data-url="' +
    urlPersonalize +
    '" data-dismiss="modal" >' +
    textNo +
    "</button>" +
    '<button class="affirm btn btn-primary" data-url="' +
    urlAccept +
    '" autofocus data-dismiss="modal">' +
    textYes +
    "</button>" +
    "</div>" +
    "</div>" +
    "</div>" +
    "</div>";
  $("body").append(htmlString);

  $.ajax({
    url: urlContent,
    type: "get",
    dataType: "html",
    success: function (response) {
      $(".consent-body").html(response);
    },
    error: function () {
      $("#consent-tracking").remove();
    },
  });

  $("#consent-tracking .button-wrapper .affirm").click(function (e) {
    e.preventDefault();
    var url = $(this).data("url");
    var data = {};
    data[tokenName] = token;
    dataLayerHelper.methods.pushDataLayer(ACCEPT_ALL);

    createCookie(COOKIES_POLICY_CUSTOMIZATION, JSON.stringify(ACCEPT_ALL_COOKIES), 180);
    createCookie(ITA_SPA_COOKIE_CONFIRMED, "true", 180);

    $.ajax({
      url: url,
      type: "post",
      data: data,
      dataType: "json",
      success: function (response) {
        // Only hide modal if the operation is successful - don't want to give a false impression
        if (response.success) {
          $("#consent-tracking").remove();
        }
      },
      error: function (err) {
        // Expected error response is for CSRF failure, which will include a redirect to CSRF-Fail
        if (err.responseJSON.redirectUrl) {
          window.location.href = err.responseJSON.redirectUrl;
        }
      },
    });
  });
  $("#consent-tracking .close-btn").click(function (e) {
    e.preventDefault();
    $("#consent-tracking").remove();
  });
  $("#consent-tracking .personalize").click(function (e) {
    e.preventDefault();
    showPersonalizatioModal(urlPersonalize);
  });
}

module.exports = function () {
  // Disable default cookie settings 
  /*   if (
    !checkCookie(COOKIES_POLICY_CUSTOMIZATION) &&
    $(".consented").length === 0 &&
    $(".tracking-consent").hasClass("api-true")
  ) {
    dataLayerHelper.methods.pushDataLayer(REJECT_ALL);
    showConsentModal();
  } else  */
  if (checkCookie(NEW_COOKIE_NAME)) {
    const name = checkCookie(NEW_COOKIE_NAME, true)
    const cookies = getCookie(name);
    const customization = JSON.parse(cookies);
    const enabled =  "purposes" in customization && customization.purposes[3]
    if (enabled) {
      var trackingConsentData = $(".tracking-consent");
      var urlAccept = trackingConsentData.data("accept");
      var tokenName = trackingConsentData.data("tokenname");
      var token = trackingConsentData.data("token");
      var data = {};
      data[tokenName] = token;
      $.ajax({
        url: urlAccept,
        type: "post",
        data: data,
        dataType: "json",
      });
    }
  }

  // Disable default cookie settings
  /* if ($(".tracking-consent").hasClass("api-true")) {
    $(".tracking-consent").click(function () {
      showConsentModal();
    });
  } */

  $("body").on("shown.bs.modal", "#consent-tracking", function () {
    $("#consent-tracking").siblings().attr("aria-hidden", "true");
    $("#consent-tracking .affirm").focus();
  });

  $("body").on("hidden.bs.modal", "#consent-tracking", function () {
    $("#consent-tracking").siblings().attr("aria-hidden", "false");
  });

  $("body").on("keydown", "#consent-tracking", function (e) {
    var focusParams = {
      event: e,
      containerSelector: "#consent-tracking",
      firstElementSelector: ".affirm",
      lastElementSelector: ".decline",
      nextToLastElementSelector: ".affirm",
    };
    focusHelper.setTabNextFocus(focusParams);
  });

   // new language cookie
 const currentLocale = $('html')[0].lang
 if (!checkCookie(LANGUAGE_COOKIE_NAME)) {
     createCookie(LANGUAGE_COOKIE_NAME, currentLocale, 180);
 } else if (typeof currentLocale !== "undefined" && currentLocale !== getCookie(LANGUAGE_COOKIE_NAME)) {
     createCookie(LANGUAGE_COOKIE_NAME, currentLocale, 180);
 }
};
