"use strict";

/**
 * Show a spinner inside a given element
 * @param {element} $target - Element to block by the veil and spinner.
 *                            Pass body to block the whole page.
 */
function addSpinner($target) {
  var $veil = $('<div class="veil"></div>');
  var playerJSON = require("../../json/loader.json");
  var $player = $("<lottie-player></lottie-player>");
  $player.attr("id", "lottie-player");
  $player.attr("background", "transparent");
  $player.attr("speed", "1");
  $player.attr("style", "width: 300px; height: 300px;margin: auto;");
  $player.attr("loop", "true");
  $player.attr("autoplay", "true");
  $player.attr("src", JSON.stringify(playerJSON))

  $veil.append($player);
  $target.append($veil);
  if ($target.css("position") === "static") {
    $target.parent().css("position", "relative");
    $target.parent().addClass("veiled");
  }
  if ($target.get(0).tagName === "BODY") {
    $veil.find(".spinner").css("position", "fixed");
  }

  $veil.click(function (e) {
    e.stopPropagation();
  });
}

/**
 * Remove existing spinner
 * @param  {element} $veil - jQuery pointer to the veil element
 */
function removeSpinner($veil) {
  if ($veil.parent().hasClass("veiled")) {
    $veil.parent().css("position", "");
    $veil.parent().removeClass("veiled");
  }
  $veil.off("click");
  $veil.remove();
}

// element level spinner:
$.fn.spinner = function () {
  var $element = $(this);
  var Fn = function () {
    this.start = function () {
      if ($element.length) {
        addSpinner($element);
      }
    };
    this.stop = function () {
      if ($element.length) {
        var $veil = $(".veil");
        removeSpinner($veil);
      }
    };
  };
  return new Fn();
};

// page-level spinner:
$.spinner = function () {
  var Fn = function () {
    this.start = function () {
      addSpinner($("body"));
    };
    this.stop = function () {
      removeSpinner($(".veil"));
    };
  };
  return new Fn();
};
