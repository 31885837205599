"use strict";

const COOKIES_POLICY_CUSTOMIZATION = "cookiesPolicyCustomization";

function checkCookie(c_name) {
  return document.cookie.indexOf(c_name + "=") !== -1;
}

function getCookie(c_name) {
  if (document.cookie.length > 0) {
    var c_start = document.cookie.indexOf(c_name + "=");
    if (c_start != -1) {
      c_start = c_start + c_name.length + 1;
      var c_end = document.cookie.indexOf(";", c_start);
      if (c_end == -1) {
        c_end = document.cookie.length;
      }
      return unescape(document.cookie.substring(c_start, c_end));
    }
  }
  return "";
}

const pushData = (data) => {
  let customization = data;
  if (typeof data === "string") {
    customization = JSON.parse(data);
  }
  if('date' in customization) {
    delete customization.date;
  }
  const dataLayer = window.dataLayer || [];
  dataLayer.push(customization);
};
const pushCookies = (c_name) => {
  if (checkCookie(c_name)) {
    const cookies = getCookie(c_name);
    pushData(cookies);
  }
};

module.exports = {
  pushCookies: pushCookies(COOKIES_POLICY_CUSTOMIZATION),
  methods: {
    pushDataLayer: function (data) {
      pushData(data);
    },
  },
};
