'use strict';

window.app = {
    device: {
        mobile: Number('0'.replace(/\D/g, '')),
        tablet: Number('768'.replace(/\D/g, '')),
        desktop: Number('1024'.replace(/\D/g, ''))
    },
    /**
     * Is Tablet
     * @return {boolean} - Whether is tablet
     */
    isTablet: function () {
        return window.matchMedia('(min-width:' + this.device.tablet + 'px)').matches;
    },
    /**
     * Is Desktop
     * @return {boolean} - Whether is desktop
     */
    isDesktop: function () {
        return window.matchMedia('(min-width:' + this.device.desktop + 'px)').matches;
    },
    /**
     * Is Mobile
     * @return {boolean} - Whether is mobile
     */
    isMobile: function () {
        return !this.isDesktop() && !this.isTablet();
    },
};
