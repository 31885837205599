


const header = () => {
    $('.js-profile-toggle').on('click', function (e) {
        e.preventDefault();
        $('.dropdown-customer').toggleClass('is-open');
        $(this).find(".white-arrow__down").toggleClass("rotate");

    }
    );
};

module.exports = {
    header,
    methods: {},
};
