function collapseMobileLogic() {
    if (screen.width > 1024) {
        $(".footer-firstlev-title").attr("data-toggle", "");
        $(".footer-secondlev-list").removeClass("collapse");
    } else {
        $(".footer-firstlev-title").attr("role", "button");
        $(".footer-firstlev-title").attr("data-toggle", "collapse");
        $(".footer-secondlev-list").addClass("collapse");
    }
}


function tooltipMobileLogic() {
    if (screen.width > 1024) {
        $('.contactus-info-icon').off('mouseenter').on('mouseenter', function () {
            var contactusTooltip = $(this).next();
            contactusTooltip.show();
        });
        $('.contactus-info-icon').off('mouseleave').on('mouseleave', function () {
            var contactusTooltip = $(this).next();
            contactusTooltip.hide();
        });
    } else {
        $('.contactus-info-icon').off('mouseenter');
        $('.contactus-info-icon').off('mouseleave');
    }
}

const footer = () => {
    $("footer .footer-firstlev-title").on("click", function (e) {
        $(this).find(".white-arrow__down").toggleClass("rotate");
    });

    collapseMobileLogic();
    tooltipMobileLogic();
    $(window).on('resize', function () {
        collapseMobileLogic();
        tooltipMobileLogic();
    });

    $(".contactus-info-icon").on("click", function () {
        var contactusTooltip = $(this).next();

        if (contactusTooltip.is(":hidden")) {
            $(".contactus-tooltip").hide();
            contactusTooltip.show();
        } else {
            contactusTooltip.hide();
        }
    });
    $(document).on("mouseup", function (e) {
        var contactsTooltip = $(".contactus-airways .contactus-info-icon, .contactus-airways .contactus-tooltip");

        if (!contactsTooltip.is(e.target) && contactsTooltip.has(e.target).length === 0) {
            $(".contactus-tooltip").hide();
        }
    });
};

module.exports = {
    footer,
    methods: {},
};
